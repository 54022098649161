@import "https://fonts.googleapis.com/css?family=Roboto:400,300,500";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Rubik:wght@500&display=swap";

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./../font/MaterialIcons-Regular.woff2) format("woff2"),
    url(./../font/MaterialIconsOutlined-Regular.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(./../font/MaterialIconsOutlined-Regular.woff2) format("woff2");
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -moz-font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #fff;
  color: #444;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.container-fluid {
  max-width: 2000px;
}

main {
  background: #fff;
}

a {
  text-decoration: none !important;
  color: #000;
}

a:hover {
  color: rgb(255, 1, 125) !important;
}

.btn-primary {
  color: #fff;
  background-color: #ff2890;
  border-color: #ff2890;
  box-shadow: 0 4px 6px rgba(93, 50, 72, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-primary:hover {
  color: #fff;
  background-color: #ff017d;
  border-color: #ff017d;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ff2890;
  border-color: #ff2890;
}

.listbadget {
  border: 1px solid #ccc !important;
  box-sizing: border-box;
  border-radius: 10px;
  background: none;
  text-align: center;
  color: #595959;
  font-weight: normal;
  cursor: pointer;
  font-size: 14px;
}

.listbadget:hover,
.listbadget.active {
  background-color: #fd476336;
  border-color: #fd476336 !important;
}

.card:hover .btnsection {
  display: block;
}

.main-content {
  background: #fff;
  /* background: #f4f4f4; */
  /* background: linear-gradient(180deg, #F6FAFD 0%, #FFFFFF 19.4%); */
  min-height: 100vh;
}

.main-content-inner {
  background: #f6fafd;
  position: relative;
}

.btnsection {
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 4px;
  text-align: center;
  display: none;
  border: none;
  position: absolute;
  right: 6px;
  top: 6px;
}

.btnsection .btn:after {
  display: none;
}

.btnsection .btn {
  width: 32px;
  height: 32px;
}

.btnsection .dropdown-menu .dropdown-item {
  padding: 0.4rem 1rem;
}

.ndrop {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  overflow: hidden;
}

.ndrop a {
  font-size: 18px;
  line-height: 33px;
  color: #595959;
  border: none;
}

.ndrop a:hover {
  background: #f5f5f5;
  color: #666;
}

.build-template {
  margin-top: 8px !important;
}

.build-template.dropdown-menu .dropdown-item {
  padding: 0.4rem 1rem;
}

.template-list {
  background-color: #eee;
  height: 135px;
}

.template-list img {
  height: 100%;
  width: auto;
}

.whitebox {
  /* background: #ffffff; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 16px;
  padding: 20px;
  margin: 24px 0;
}

.whitebox h1,
.whitebox .line-action {
  font-style: normal;
  font-weight: bold;
  font-size: 3rem !important;
  line-height: 56px !important;
  color: #000 !important;
  margin-top: 20px;
  text-align: left;
  padding-left: 10px;
}

.whitebox .line-action {
  margin-top: 15px !important;
  color: #ff017d !important;
  cursor: pointer;
}

.sharelist {
  list-style: none;
  padding: 0;
  margin: 24px 0 4px;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.sharelist li {
  display: inline-block;
  position: relative;
}

.sharelist li .clearicon {
  color: #ff017d;
  display: none;
  cursor: pointer;
  margin-left: 5px;
  margin-right: -10px;
  font-size: 18px;
}

.sharelist li.active .clearicon {
  display: block;
}

.sharelist li .item {
  background: #ffffff;
  border: 2px solid #33334f;
  box-sizing: border-box;
  border-radius: 32px;
  padding: 8px 16px;
  text-align: center;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 19px;
  color: #33334f;
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.sharelist li .item:hover,
.sharelist li.active .item {
  border-color: rgb(255, 1, 125);
  background-color: #ffe3f1;
  color: rgb(255, 1, 125);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.cardbox {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cardbox.template-card:before {
  background-color: rgba(128, 128, 128, 0.4);
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  z-index: 99;
  display: none;
  /* bottom: -1000px; */
}

.cardbox.template-card:hover:before {
  display: block;
}

.cardbox:hover .btnbox {
  bottom: 0px;
  transition-duration: 0.3s;
}

.cardbox .preview-btn,.cardbox .export-btn {
  position: absolute;
  right: 15px;
  top: -50px;
  z-index: 999;
  color: rgb(255, 1, 125);
  font-size: 20px;
  cursor: pointer;
  transition-duration: 0.3s;
  background: #fff;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardbox:hover .preview-btn {
  top: 15px;
  transition-duration: 0.3s;
}

.cardbox:hover .export-btn {
  top: 50px;
  transition-duration: 0.3s;
}

.cardbox .clone-btn {
  position: absolute;
  left: 15px;
  bottom: -50px;
  z-index: 999;
  color: rgb(255, 1, 125);
  font-size: 20px;
  cursor: pointer;
  transition-duration: 0.3s;
  background: #fff;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardbox:hover .clone-btn {
  bottom: 15px;
  transition-duration: 0.3s;
}

.cardbox img {
  max-height: 100%;
  max-width: 100%;
  border: 1px solid #eee;
  border-radius: 15px;
  height: auto;
}

.h185 {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.newcardbox {
  height: 95%;
}

.cardbox.active {
  border: 4px solid #ff017d;
  box-shadow: none;
}

.cardbox .assign-tag {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -100px;
  transition-duration: 0.3s;
  z-index: 99;
}

.cardbox .assign-tag span {
  background: #fff;
  border-radius: 16px;
  padding: 5px 10px;
  font-size: 12px;
}

.cardbox:hover .assign-tag {
  bottom: 55px;
  transition-duration: 0.3s;
}

.boxtitle {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #33334f;
}

.boxtitle span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.template-content-list .cardbox+.card-body {
  padding: 16px 0;
}

.template-content-list .cardbox+.card-body i {
  cursor: pointer;
  font-size: 18px;
  margin-top: 2px;
}

.template-content-list .cardbox+.card-body i:hover {
  cursor: pointer;
  color: rgb(255, 1, 125);
}

.template-content-list .publish-status-icon {
  font-size: 16px;
  top: 2px;
  position: relative;
  cursor: pointer;
}

.template-content-list .publish-status-icon.active {
  color: #2fdd60;
}

.template-content-list .publish-status-icon.inactive {
  color: #f8b300;
}

.mainrow .row-header-t {
  margin-top: 8px;
  margin-bottom: 16px;
}

.tablediv {
  overflow: hidden;
  min-height: 250px;
  border-radius: 16px;
  position: relative;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(90, 97, 105, 0.1) 0px 0.46875rem 2.1875rem,
    rgba(90, 97, 105, 0.1) 0px 0.9375rem 1.40625rem,
    rgba(90, 97, 105, 0.12) 0px 0.25rem 0.53125rem,
    rgba(90, 97, 105, 0.1) 0px 0.125rem 0.1875rem;
}

.img-box {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 235px;
}

.img-box img {
  max-height: 100%;
}

.sizelist {
  list-style: none;
  padding: 0;
  margin: 0;
  /* text-align: center; */
  display: flex;
  align-items: center;
}

.sizelist li {
  display: inline-block;
  margin: 16px 8px;
}

.sizelist li:first-child {
  margin-left: 0;
}

.sizelist li .template__box {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 157px;
  height: 187px;

  /* Cliquify/White */
  background: #FFFFFF;

  /* Cliquify/CF Grey 40% */
  border: 1px solid #DEE4ED;
  border-radius: 8px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.sizelist li.active .template__box,
.sizelist li:active .template__box {
  border: 1px solid #ff017d;
}

.sizelist li .template__text {
  padding: 10px;
}

.sizelist li .template__compatible_formats {
  display: flex;
  margin-top: 16px;
}
.sizelist li .template__compatible_formats span {
  margin-right: 4px;
  width: 20px;
  height: 20px;

  /* Cliquify/CF Grey 20% */
  background: #EBF1F5;
  border-radius: 2px;
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #818EA3;
}

.sizelist li:hover {
  cursor: pointer;
}

.sizelist li img {
  max-height: 150px;
  max-width: 150px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.sizelist li:hover img {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.08));
  -webkit-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  transform: translateY(-4px);
}

.sizelist li.active img {
  border: 2px solid #f063b8;
}

.template__caption {
  max-height: 32px;
  margin: 0;
  color: #000;
  overflow: hidden;
}

.template__dimensions,.template__orientation {
  color: #91949c;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.btnbox {
  background: #ffffff;
  box-shadow: 0px -7px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 16px;
  bottom: -100px;
  position: absolute;
  width: 100%;
  transition-duration: 0.3s;
  z-index: 99;
  border-top: 2px solid #ff017d;
}

.main-text {
  font-size: 2rem !important;
  line-height: 2.8rem !important;
}

.btnbox i {
  font-size: 20px;
}

.pointer {
  color: #ff017d;
  cursor: pointer;
}

i.material-icons {
  top: 2px;
}

i.material-icons {
  position: relative;
}

@media (min-width: 1200px) {
  .xxlsize {
    max-width: 20% !important;
  }
}

@media (min-width: 1600px) {
  .xxlsize {
    max-width: 16.6% !important;
  }
}

@media (min-width: 1500px) {
  .listcard h4 {
    font-size: 24px !important;
  }

  .listcard p {
    font-size: 16px !important;
  }
}

.swal2-styled.swal2-confirm:focus {
  outline: 0;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(255, 1, 125, 0.4);
}

.page-header .page-title {
  font-size: 1.4rem !important;
  color: #000 !important;
  font-weight: 500;
}

.card-body>p:last-child {
  margin-bottom: 0;
}

.socialbtn i {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background-color: #818ea3;
  color: #fff;
  display: inline-block;
  margin: 5px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
}

.btn-linkedin {
  color: #fff;
  background-color: #0e76a8;
  border-color: #0e76a8;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-linkedin:hover {
  color: #fff;
  background-color: #0e76a8;
  border-color: #0e76a8;
}

.footericon a {
  padding: 5px;
  margin-left: 20px;
  color: #33334f;
}

.shadow-navbar {
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.loginbtn {
  padding: 0.35rem 1.5rem !important;
  line-height: 1.5;
  border: solid 1px #333;
  border-radius: 8px;
  font-size: 1rem;
  color: #333 !important;
}

.loginbtn:hover,
.loginbtn:active,
.loginbtn.active {
  border-color: #ff017d;
  background: rgba(255, 1, 125, 0.1) !important;
  color: #ff017d !important;
  box-shadow: none !important;
}

.loginbtn:focus {
  box-shadow: none !important;
}

.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navBar-button {
  background-color: white;
  color: #ff017d;
  font-size: 19px;
  padding: 2px 10px;
  margin-right: 10px;
  border: black 1px solid;
  box-shadow: none;
  border-radius: 10px;
  text-align: center;
  font-weight: 600;
}

.navbar-Dropdown {
  overflow-y: auto;
  height: 300px;
  margin-top: 22px;
}

h5#createModalLabel {
  color: #000;
  font-weight: 700;
  font-family: 'Inter'
}

.listingbtn {
  background-color: #F5F8FA;
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
}

.listingbtn span {
  background-color: transparent;
  color: #33334f;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;

}

.listingbtn span.actives {
  background-color: #fff;
  color: #000000;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid #EBF1F5;
  box-shadow: 0px 15px 6px rgba(0, 0, 0, 0.01), 0px 8px 5px rgba(0, 0, 0, 0.02), 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
}

.modal .modal-fullscreen.modal-dialog {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  max-width: none;
}

.modal .modal-fullscreen .modal-content {
  height: auto;
  height: 100vh;
  border-radius: 0;
  border: none;
}

.modal .modal-fullscreen .modal-body {
  overflow-y: auto;
}

.template__box .portrait {
  width: 114px;
  height: 64px;

  /* Cliquify/Blue */
  background: #5182FF;
  opacity: 0.2;
  border-radius: 8px;
}

.template__box .post {
  width: 64px;
  height: 64px;

  /* Cliquify/Green */
  background: #2FDD60;
  opacity: 0.2;
  border-radius: 8px;
}

.template__box .story {
  width: 114px;
  height: 64px;

  /* Cliquify/Yellow */
  background: #F8B300;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(-90deg);
}

.template__box .a4 {
  width: 90px;
  height: 64px;

  /* Cliquify/CF Grey 80% */
  background: #767EAD;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(-90deg);
}

.template__box .letter {
  width: 90px;
  height: 64px;

  /* Cliquify/CF Grey 80% */
  background: #767EAD;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(-90deg);
}

.template__box .facebook {
  width: 114px;
  height: 64px;

  /* Cliquify/Blue */
  background: #5182FF;
  opacity: 0.2;
  border-radius: 8px;
}

.template__box .linkedIn {
  width: 114px;
  height: 72px;

  /* Cliquify/Button Hover */
  background: linear-gradient(0deg, #5F51FF -138.39%, #FF017D 100%);
  opacity: 0.2;
  border-radius: 8px;
}

.template__box .twitter {
  width: 99px;
  height: 56px;

  /* Cliquify/Blue Gradient */
  background: linear-gradient(113.98deg, #5F51FF 13.56%, #4239B3 93.12%);
  opacity: 0.2;
  border-radius: 8px;
}

.template__box .a5 {
  width: 119px;
  height: 84px;

  /* Cliquify / Bluegray */
  background: #767EAD;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(-90deg);
}

.template__box .poster {
  width: 114px;
  height: 88px;

  /* Suggested / Medium Gray */
  background: #6B778E;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(-90deg);
}

.template__box .card {
  width: 118px;
  height: 85px;

  /* Cliquify/Mediumgray */
  background: #818EA3;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(-90deg);
}

.template__box .card_1 {
  width: 125px;
  height: 75px;

  /* Cliquify/Font */
  background: #33334F;
  opacity: 0.2;
  border-radius: 8px;
  transform: rotate(-90deg);
}

.post-container {
  display: flex;
  justify-content: center;
}

.size-container {
  padding: 80px 0 70px;
}

.size-container .post-custom-size-form {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  display: grid;
  justify-items: stretch;
  list-style: none;
  margin: 0;
  padding: 0;
  width: auto;
  grid-template-columns: 150px 30px 150px 70px;
  align-items: center;
}

.size-container .post-custom-size-form .form-group span {
  display: block;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #33334F;
  width: 150px;
  margin: 5px 5px 5px 0;
  text-align: center;
}

.size-container .post-custom-size-form .post-swap-button {
  margin: 15px 0 0;
  font-size: 26px;
  cursor: pointer;
}

.size-container .post-custom-size-form .post-units-dropdown {
  margin: 28px 0 0;
}